import React, { useState, useEffect, useContext, Suspense, lazy } from 'react'
import { Route, Switch, useLocation, Redirect, useHistory } from "react-router-dom"
import axios from "axios"
import Cookies from "js-cookie"
import moment from "moment"
import headful from 'headful'
import Loader from "./components/assets/loader"
import GDPR from "./components/assets/gdpr"
import { I18nContext } from './languages/i18n'
import ReactPixel from 'react-facebook-pixel'
import Messenger from 'react-messenger-customer-chat'
import MainHeader from "./components/assets/headers/main-header"
import LandingHeader from "./components/assets/headers/landing-header"
import SkeletonFullPage from "./skeletons/skeletonFullPage"

const Landing = React.lazy(() => import('./components/landing2'))
const Login = React.lazy(() => import('./components/login'))
const Register = React.lazy(() => import('./components/register'))
const Prices = React.lazy(() => import('./components/prices'))
const ResetPassword = React.lazy(() => import('./components/resetPassword'))
const ChangePassword = React.lazy(() => import('./components/changePassword'))
const Intro = React.lazy(() => import('./components/intro'))
const ChooseDesign = React.lazy(() => import('./components/chooseDesign'))
const CreateInvoiceDesign = React.lazy(() => import('./components/createInvoiceDesign/createInvoiceDesign'))
const Subscribtions = React.lazy(() => import('./components/subscribtions/subscribtions'))
const ThankYou = React.lazy(() => import('./components/thankYou'))
const Success = React.lazy(() => import('./components/success'))
const Failure = React.lazy(() => import('./components/failure'))
const Stats = React.lazy(() => import('./components/stats'))
const Main = React.lazy(() => import('./components/main/main'))
const Templates = React.lazy(() => import('./components/templates'))
const Costs = React.lazy(() => import('./components/costs/costs'))
const Products = React.lazy(() => import('./components/productsClientsTaxes/products'))
const Units = React.lazy(() => import('./components/productsClientsTaxes/units'))
const Clients = React.lazy(() => import('./components/productsClientsTaxes/clients'))
const Taxes = React.lazy(() => import('./components/productsClientsTaxes/taxes'))
const Settings = React.lazy(() => import('./components/settings'))
const CreateInvoice = React.lazy(() => import('./components/createInvoice/createInvoice'))
const CreateCost = React.lazy(() => import('./components/createCost/createCost'))
const Contacts = React.lazy(() => import('./components/contacts'))
const PrivacyPolicy = React.lazy(() => import('./components/privacyPolicy'))
const LandingFooter = React.lazy(() => import('./components/assets/footers/landing-footer'))
const MainFooter = React.lazy(() => import('./components/assets/footers/main-footer'))

const App = (props) => {
	// const [loading, setLoading] = useState(true)
	const [isAuth, setIsAuth] = useState(false)
	const [user, setUser] = useState('')
	const [showCookieMessage, setShowCookieMessage] = useState('true')
	const [currentDate, setCurrentDate] = useState(null)
	const [expire, setExpire] = useState('')
	const [expirationD, setExpirationD] = useState('---')
	const [expirationH, setExpirationH] = useState('---')

	const { langCode, translate, mainLink } = useContext(I18nContext)
	let location = useLocation()
	let history = useHistory()
	const Header = <MainHeader
		firstTimer={isAuth && user.firstTimer}
		noBalance={isAuth && user.noBalance}
		isAuth={isAuth}
		expirationD={isAuth && expirationD}
		expirationH={isAuth && expirationH}
		location={location}
	/>

	headful({
	  title: langCode === 'en' ? 'INVoicer24 | Easy invoice managing' : 'INVoicer24 | Lengvas sąskaitų valdymas',
	  description: langCode === 'en' ? 'Fast invoicing, sending to the customers, or generating invoices to .pdf format, properly representing your company and storing it in the database.' : 'Greitas sąskaitų faktūrų išrašymas, siuntimas klientui, ar jų generavimas .pdf formatu, tinkamai reprezentuojant Jūsų įmonę, bei saugant jas duomenų bazėje.'
	})

	useEffect(() => {
		const options = {
		  autoConfig: true, // set pixel's autoConfig
		  debug: false, // enable logs
		}
		ReactPixel.init('262252468431709', options);
		ReactPixel.pageView()
	}, [])

	useEffect(() => {
//		axios.get('https://worldtimeapi.org/api/timezone/Etc/UTC').then(res => setCurrentDate(res.data.datetime))
		setCurrentDate(moment().format())
	}, [])

	useEffect(() => {
		let unmounted = false

		const token = Cookies.get('invoicer24')
		const cookieMessage = Cookies.get('invoicer24_privacy')

		if(cookieMessage) {
			setShowCookieMessage(false)
		}

		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		if(token) {
			axios.get(`${mainLink}/users/me`, {
				headers: {
				  Authorization: "Bearer " + token
			   }
			}).then(res => {
				if(res.data && !unmounted) {
					setIsAuth(true)
					setUser(res.data)
					setExpire(res.data.expire)
				}
			})

			return () => unmounted = true
		} else {
			setIsAuth(false)
		}
	}, [])

	const updatingUserHandler = (entry, type) => {
		let userContainer = {...user}

		if(!type) {
			userContainer.firstTimer[entry] = true
		}
		if(type && type === 'chooseDesign') {
			userContainer.invoiceStyle = entry
		}
		if(type && type === 'createDesign') {
			userContainer = {...userContainer, ...entry}
		}

		setUser(userContainer)
	}

	useEffect(() => {
		if(currentDate && expire !== '') {
			const currDate = moment(currentDate)
			const expDate = moment(expire)
			setExpirationD(expDate.diff(currDate, 'days'))
			setExpirationH(expDate.diff(currDate, 'hours'))
		}
	}, [currentDate, expire])

	let routes

	if(isAuth && user.firstTimer && user.firstTimer.finished) {
		if(expirationH >= 1) {
			routes = (
				<Switch>
					<Route path="/stats"><Stats/></Route>
					<Route path="/home"><Main/></Route>
					<Route path="/templates"><Templates/></Route>
					<Route path="/costs"><Costs/></Route>
					<Route path="/products"><Products user={user} history={history}/></Route>
					<Route path="/units"><Units user={user} history={history}/></Route>
					<Route path="/clients"><Clients user={user} history={history}/></Route>
					<Route path="/taxes"><Taxes user={user} history={history}/></Route>
					<Route path="/settings"><Settings updatingUser={updatingUserHandler} history={history} expirationD={expirationD} expirationH={expirationH} /></Route>
					<Route exact path="/template"><CreateInvoice user={user}/></Route>
					<Route path="/template/:id"><CreateInvoice user={user}/></Route>
					<Route exact path="/invoice"><CreateInvoice user={user}/></Route>
					<Route path="/invoice/:id"><CreateInvoice user={user}/></Route>
					<Route exact path="/cost"><CreateCost user={user}/></Route>
					<Route path="/cost/:id"><CreateCost user={user}/></Route>
					<Route path="/subscriptions"><Subscribtions updatingUser={updatingUserHandler} user={user} history={history} currentDate={currentDate} /></Route>
					<Route path="/success"><Success/></Route>
					<Route path="/failure"><Failure/></Route>
					<Route path="/contacts"><Contacts/></Route>
					<Route path="/choose-design"><ChooseDesign user={user} history={history} updatingUser={updatingUserHandler}/></Route>
					<Route path="/create-design"><CreateInvoiceDesign history={history} updatingUser={updatingUserHandler}/></Route>
					<Route path="/privacy-policy"><PrivacyPolicy/></Route>
					<Route path="/thank-you"><ThankYou/></Route>
					<Route path="/prices"><Prices/></Route>
					<Redirect from="/" to='home'/>
					<Redirect from="/reset-password" to='/'/>
					<Redirect from="/change-password" to='/'/>
				</Switch>
			)
		} else if(expirationH < 1) {
			routes = (
				<Switch>
					<Route exact path="/subscriptions"><Subscribtions updatingUser={updatingUserHandler} user={user} history={history} currentDate={currentDate} /></Route>
					<Route path="/success"><Success/></Route>
					<Route path="/failure"><Failure/></Route>
					<Route path="/privacy-policy"><PrivacyPolicy/></Route>
					<Route path="/thank-you"><ThankYou/></Route>
					<Route path="/prices"><Prices/></Route>
					<Redirect from="/" to='subscriptions'/>
					<Redirect from="/stats" to='subscriptions'/>
					<Redirect path="/login" to='/subscriptions'/>
					<Redirect path="/register" to='/subscriptions'/>
					<Redirect from="/create-design" to='/subscriptions'/>
					<Redirect from="/home" to='/subscriptions'/>
					<Redirect from="/templates" to='/subscriptions'/>
					<Redirect from="/cost" to='/subscriptions'/>
					<Redirect from="/costs" to='/subscriptions'/>
					<Redirect from="/products" to='/subscriptions'/>
					<Redirect from="/units" to='/subscriptions'/>
					<Redirect from="/clients" to='/subscriptions'/>
					<Redirect from="/taxes" to='/subscriptions'/>
					<Redirect from="/settings" to='/subscriptions'/>
					<Redirect from="/reset-password" to='/subscriptions'/>
					<Redirect from="/change-password" to='/subscriptions'/>
				</Switch>
			)
		}
	} else {
		routes = (
			<Switch>
				<Route exact path="/" component={Landing}/>
				<Route path="/login" component={Login}/>
				<Route path="/register" component={Register}/>
				<Route path="/prices" component={Prices}/>
				<Route path="/reset-password" component={ResetPassword}/>
				<Route path="/change-password" component={ChangePassword}/>
				<Route path="/contacts" component={Contacts}/>
				<Route path="/privacy-policy" component={PrivacyPolicy}/>
				<Route path="/thank-you" component={ThankYou}/>
				<Redirect from="/intro" to='/'/>
				<Redirect from="/choose-design" to='/'/>
				<Redirect from="/create-design" to='/'/>
				<Redirect from="/stats" to='/'/>
				<Redirect from="/home" to='/'/>
				<Redirect from="/templates" to='/'/>
				<Redirect from="/invoice" to='/'/>
				<Redirect from="/costs" to='/'/>
				<Redirect from="/cost" to='/'/>
				<Redirect from="/products" to='/'/>
				<Redirect from="/units" to='/'/>
				<Redirect from="/clients" to='/'/>
				<Redirect from="/taxes" to='/'/>
				<Redirect from="/settings" to='/'/>
				<Redirect from="/subscriptions" to='/'/>
			</Switch>
		)
	}

	if(isAuth && user.firstTimer && !user.firstTimer.finished) {
		let link = '/intro'
		let container = <Intro user={user} history={history} updatingUser={updatingUserHandler}/>
		if(user.firstTimer.intro) {
			link = '/choose-design'
			container = <ChooseDesign user={user} history={history} updatingUser={updatingUserHandler}/>
		}
		if(user.firstTimer.chooseStyle) {
			link = '/create-design'
			container = <CreateInvoiceDesign history={history} updatingUser={updatingUserHandler}/>
		}
		if(user.firstTimer.createStyle) {
			link = '/products'
			container = <Products user={user} history={history} updatingUser={updatingUserHandler}/>
		}
		if(user.firstTimer.products) {
			link = '/clients'
			container = <Clients user={user} history={history} updatingUser={updatingUserHandler}/>
		}
		if(user.firstTimer.clients) {
			link = '/taxes'
			container = <Taxes user={user} history={history} updatingUser={updatingUserHandler}/>
		}
		if(user.firstTimer.taxes) {
			link = '/home'
			container = <Main/>
		}

		routes = (
			<Switch>
				<Route path={link} render={(props) => container}/>
				<Redirect from="/" to={link}/>
				<Route path="/contacts" component={Contacts}/>
				<Route path="/prices" component={Prices}/>
				<Route path="/thank-you" component={ThankYou}/>
				<Redirect from="/login" to={link}/>
				<Redirect from="/register" to={link}/>
				<Redirect from="/stats" to={link}/>
				<Redirect from="/home" to={link}/>
				<Redirect from="/templates" to={link}/>
				<Redirect from="/cost" to={link}/>
				<Redirect from="/products" to={link}/>
				<Redirect from="/units" to={link}/>
				<Redirect from="/clients" to={link}/>
				<Redirect from="/taxes" to={link}/>
				<Redirect from="/settings" to={link}/>
				<Redirect from="/subscriptions" to="/"/>
				<Redirect from="/success" to="/"/>
				<Redirect from="/failure" to="/"/>
				<Redirect from="/reset-password" to="/"/>
				<Redirect from="/change-password" to="/"/>
			</Switch>
		)
	}

	const agreeWithPrivPolicyHandler = () => {
		Cookies.set('invoicer24_privacy', translate('agree_p_p'), { expires: 365 })
		setShowCookieMessage(false)
	}

	return (
		<Suspense
			fallback={<SkeletonFullPage page={location}/>}>
			<div className={location.pathname !== "/" ? "second" : "first"}>
				<div className={`content ${location.pathname !== "/" ? "content2" : ""}`}>
					{location.pathname !== "/" ? Header : <LandingHeader/>}
					{routes}
				</div>
				{!isAuth &&
					<Messenger
				    pageId="100415285164437"
						appId="123919172950870"
				    themeColor="#1CBFA3"
						language= {`${langCode === 'lt' ? 'lt' : 'en_US'}`}
						loggedInGreetin= "Sveiki! Kuo galime padėti?"
						loggedOutGreeting= "Sveiki! Kuo galime padėti?"
				  />
				}
				<GDPR agreeWithPrivPolicy={agreeWithPrivPolicyHandler} showCookieMessage={showCookieMessage}/>
				{isAuth ? <MainFooter noBalance={user.noBalance}/> : <LandingFooter/>}
			</div>
		</Suspense>
	)
}

export default App
