import React from 'react'
import Skeleton from './skeletonElement'
import Shimmer from './shimmer'

const SkeletonStat = () => {
  return (
    <div className="skeleton-wrapper skeleton-stats">
      <Skeleton type="stat"/>
      <Shimmer/>
    </div>
  )
}

export default SkeletonStat
