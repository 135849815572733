import React from 'react'
import Skeleton from './skeletonElement'
import Shimmer from './shimmer'

const SkeletonController = () => {
  return (
    <div className="skeleton-wrapper">
      <Skeleton type="controller"/>
      <Shimmer/>
    </div>
  )
}

export default SkeletonController
