import React from 'react'

const Loader = (props) => {
	let style = {}
	if(props.downloadLoading) {
		style = {background: "#5a99ce"}
	} else if(props.sendLoading) {
		style = {background: "#1CBFA3"}
	} else if(props.redirectLoading) {
		style = {background: "#fff"}
	}
	
	return (
		<div className={`lds-facebook ${props.iconLoading ? 'iconLoading' : ''}`}>
			<div style={style}></div>
			<div style={style}></div>
			<div style={style}></div>
		</div>
	)
}

export default Loader