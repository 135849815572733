import React from 'react'
import Skeleton from './skeletonElement'
import Shimmer from './shimmer'

const SkeletonInput = () => {
  return (
    <div className="skeleton-wrapper">
      <Skeleton type="input"/>
      <Shimmer/>
    </div>
  )
}

export default SkeletonInput
