import React, { useContext } from 'react'
import { Link } from "react-router-dom"
import { I18nContext } from '../../../languages/i18n'

const NotLoggedMenu = props => {
	const { translate } = useContext(I18nContext)

	return (
		<div className="menu">
			<Link to="/prices">{translate('Prices')}</Link>
			<Link to="/register">{translate('register')}</Link>
			<Link to="/login">{translate('log_in')}</Link>
		</div>
	)
}


export default NotLoggedMenu
