import React from 'react'
import Skeleton from './skeletonElement'
import Shimmer from './shimmer'

const SkeletonTitleButton = ({ noButton, noMargin }) => {
  return (
    <div className="skeleton-wrapper">
      <div className={`title-button ${noMargin ? 'tb__noMargin' : ''}`}>
        <Skeleton type="title"/>
        {noButton ? '' : <Skeleton type="button"/>}
      </div>
      <Shimmer/>
    </div>
  )
}

export default SkeletonTitleButton
