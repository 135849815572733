import React, { useState, useContext } from 'react'
import NotLoggedMenu from "../menus/not-logged-menu"
import LoggedMenu from "../menus/logged-menu"
import { Link } from "react-router-dom"
import { I18nContext } from "../../../languages/i18n"

const MainHeader = props => {
	const { translate, langCode, dispatch } = useContext(I18nContext)

	let expiration
	if(props.location.pathname !== '/subscriptions' && props.firstTimer && props.firstTimer.finished && props.isAuth && props.expirationD == 1) {
		expiration = (<Link to="/subscriptions">
						<p className="w"><b>{translate('subscription_expires_in')} <span className="warning">1 {translate('day')}</span> !</b></p>
					</Link>)
	} else if(props.location.pathname !== '/subscriptions' && props.firstTimer && props.firstTimer.finished && props.isAuth && props.expirationD < 1) {
		expiration = (<Link to="/subscriptions">
						<p className="w"><b>{translate('subscription_expires_in')} <span className="warning">{props.expirationH} {props.expirationH > 1 ? translate('hours') : translate('hour')}</span> !</b></p>
					</Link>)
	} else {
		expiration = ''
	}

	const changeLanguage = (lang) => {
		dispatch({ type: "setLanguage", payload: lang });
	}

	return (
		<header>
			<div className={`header-wrapper ${props.isAuth ? "header-wrapper2" : "landing-header"}`}>
				<div className="logo">
					<Link to="/">
						<img src="/images/logo/logo-w.svg" alt=""></img>
					</Link>
				</div>
				{expiration}

				<div className="menu-lang">
					{props.isAuth ? <LoggedMenu firstTimer={props.firstTimer} noBalance={props.noBalance}/> : <NotLoggedMenu/>}
					<div className="languages">
						<div className="current-language">
							<img src={`/images/flags/${langCode}.svg`} alt=""/>
						</div>
						<div className="dropdown-language">
							<img src="/images/flags/en.svg" style={{display: `${langCode === 'en' ? 'none' : 'block'}`}} className="w" onClick={() => changeLanguage('en')} />
							<img src="/images/flags/lt.svg" style={{display: `${langCode === 'lt' ? 'none' : 'block'}`}} className="w" onClick={() => changeLanguage('lt')} />
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}
export default MainHeader
