import React, { Fragment } from 'react'
import SkeletonSavedInvoice from './skeletonSavedInvoice'
import SkeletonHeader from './skeletonHeader'
import SkeletonTitleButton from './skeletonTitleButton'
import SkeletonPCT from './skeletonPCT'
import SkeletonInput from './skeletonInput'
import SkeletonLabel from './skeletonLabel'
import SkeletonStat from '../skeletons/skeletonStat'
import SkeletonTemplate from '../skeletons/skeletonTemplate'
import SkeletonInvoice from '../skeletons/skeletonInvoice'
import SkeletonController from '../skeletons/skeletonController'

const SkeletonFullPage = ({ page }) => {
  let list, needHeader = true
  switch(page.pathname) {
    case '/home':
    case '/costs':
    list = <div className="body-wrapper-content">
             <SkeletonTitleButton/>
              <div className="all-sections all-invoices">
                <div className="year-container-invoices">
                  {[1, 2, 3, 4].map(e => <div key={e} className="section-wrapper all-invoices-wrapper"><SkeletonSavedInvoice/></div>)}
                </div>
              </div>
            </div>
    break;
    case '/invoice':
    case '/template':
    case '/cost':
    list = <div className="marginTop80 marginBottom60 invoicer-wrapper">
              <div className="invoicer">
                <SkeletonInvoice/>
              </div>
              <div className="controller-save-wrapper">
                <SkeletonController/>
              </div>
            </div>
    break;
    case '/create-design':
    list = <Fragment>
            <SkeletonTitleButton noButton noMargin/>
            <div className="marginBottom60 invoicer-wrapper">
              <div className="invoicer">
                <SkeletonInvoice/>
              </div>
              <div className="controller-save-wrapper">
                <SkeletonController/>
              </div>
            </div>
           </Fragment>
    break;
    case '/templates':
    case '/products':
    case '/clients':
    case '/taxes':
      list = <div className="body-wrapper-content">
                <SkeletonTitleButton/>
                <div className="all-sections all-sections-width">
                  {[1, 2, 3].map(e => <SkeletonPCT key={e}/>)}
                </div>
             </div>
    break;
    case '/settings':
      list = <div className="body-wrapper-content">
              <SkeletonTitleButton/>
              <div className="log-reg form-btn-right">
                {[1, 2, 3, 4, 5, 6].map(e =>
                  <Fragment key={e}>
                    <SkeletonLabel/>
                    <SkeletonInput/>
                  </Fragment>)}
               </div>
             </div>
    break;
    case '/stats':
      list = <div className="body-wrapper-content">
                <SkeletonTitleButton noButton/>
                <div className="stats" style={{ marginTop: '65px' }}>
                  {[1, 2, 3, 4].map(e => <SkeletonStat className="w30" key={e}/>)}
                </div>
             </div>
    break;
    case '/choose-design':
      list = <div className="body-wrapper-content">
                <SkeletonTitleButton noButton noMargin/>
                <div className="invoices-style">
                  {[1, 2, 3].map(e => <SkeletonTemplate key={e}/>)}
                </div>
              </div>
    break;
    case '/subscriptions':
    case '/prices':
      list = <div className="body-wrapper-content">
                <SkeletonTitleButton noButton noMargin/>
                <div className="subscribtions">
                  {[1, 2, 3, 4].map(e => <SkeletonStat key={e}/>)}
                </div>
              </div>
    break;
    case '/login':
    case '/register':
      list = <div className="body-wrapper-content">
              <SkeletonTitleButton noButton/>
              <div className="log-reg form-btn-right">
                {[1, 2, 3, 4, 5, 6].map(e =>
                  <Fragment key={e}>
                    <SkeletonLabel/>
                    <SkeletonInput/>
                  </Fragment>)}
               </div>
             </div>
    break;
    case '/':
      needHeader = false
      list = <div className="loading-body">
               <embed src="/animations/main-loader/loaderis.html" width="550" height="400"/>
             </div>
    break;
    default:
      list = <div className="body-wrapper-content"></div>
  }

  return (
    <Fragment>
      {needHeader && <SkeletonHeader/>}
      <div className="body-wrapper" style={{ marginTop: '0px' }}>
        {list}
      </div>
    </Fragment>
  )
}

export default SkeletonFullPage
