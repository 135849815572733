import React from 'react'
import Skeleton from './skeletonElement'
import Shimmer from './shimmer'

const SkeletonInvoice = () => {
  return (
    <div className="skeleton-wrapper">
      <Skeleton type="invoice"/>
      <Shimmer/>
    </div>
  )
}

export default SkeletonInvoice
