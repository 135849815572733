import React from 'react'
import Skeleton from './skeletonElement'
import Shimmer from './shimmer'

const SkeletonTemplate = () => {
  return (
    <div className="skeleton-wrapper skeleton-templates">
      <Skeleton type="template"/>
      <Shimmer/>
    </div>
  )
}

export default SkeletonTemplate
