import React from 'react'
import Skeleton from './skeletonElement'
import Shimmer from './shimmer'

const SkeletonHeader = () => {
  return (
    <div className="skeleton-wrapper">
      <Skeleton type="header"/>
      <Shimmer/>
    </div>
  )
}

export default SkeletonHeader
