import React, {Fragment} from 'react'
import {Link} from "react-router-dom"

export const WrapperST = (props) => {
	let wrap = 	<li className={props.noBalance ? "menu_item__doNotShow_allScreens" : "menu_item__putBorder menu_item__show"}>
					<Link to="/stats">
						{props.children}
					</Link>
				</li>
	if(props.active === "st") {
		wrap = <li className={`menu-active ${props.noBalance ? "menu_item__doNotShow_allScreens" : "menu_item__putBorder menu_item__show"}`}>
					<Link to="/">
						{props.children}
					</Link>
				</li>
	}
	if(props.firstTimer && !props.firstTimer.finished) {
		wrap = 	<Fragment>
					<li className={`firstTimer ${props.noBalance ? "menu_item__doNotShow_allScreens" : "menu_item__putBorder menu_item__show"}`}>
						{props.children}
					</li>
				</Fragment>
	}
	return (
		wrap
	)
}

export const WrapperI = (props) => {
	let wrap = 	<li className="menu_item__show menu_item__putBorder">
					<Link to="/">
						{props.children}
					</Link>
				</li>
	if(props.active === "i") {
		wrap = <li className="menu-active menu_item__show menu_item__putBorder">
					<Link to="/">
						{props.children}
					</Link>
				</li>
	}
	if(props.firstTimer && !props.firstTimer.finished) {
		wrap = 	<Fragment>
					<li className="firstTimer menu_item__show menu_item__putBorder">
						{props.children}
					</li>
				</Fragment>
	}
	return (
		wrap
	)
}

export const WrapperCO = (props) => {
	let wrap = 	<li className={props.noBalance ? "menu_item__doNotShow_allScreens" : "menu_item__putBorder menu_item__show"}>
					<Link to="/costs">
						{props.children}
					</Link>
				</li>
	if(props.active === "co") {
		wrap = <li className={`menu-active ${props.noBalance ? "menu_item__doNotShow_allScreens" : "menu_item__putBorder menu_item__show"}`}>
					<Link to="/">
						{props.children}
					</Link>
				</li>
	}
	if(props.firstTimer && !props.firstTimer.finished) {
		wrap = 	<Fragment>
					<li className={`firstTimer ${props.noBalance ? "menu_item__doNotShow_allScreens" : "menu_item__putBorder menu_item__show"}`}>
						{props.children}
					</li>
				</Fragment>
	}
	return (
		wrap
	)
}

export const WrapperP = (props) => {
	let wrap = 	<li className="menu_item__show menu_item__putBorder">
					<Link to="/products">
						{props.children}
					</Link>
				</li>
	if(props.active === "p") {
		wrap = <li className="menu-active menu_item__show menu_item__putBorder">
					<Link to="/products">
						{props.children}
					</Link>
				</li>
	}
	if(props.firstTimer && !props.firstTimer.finished) {
		wrap = 	<Fragment>
					<li className="firstTimer menu_item__show menu_item__putBorder">
						{props.children}
					</li>
				{props.firstTimer.createStyle && !props.firstTimer.products ? <li className="active-guide-product"><img src="images/guide/guide-products2.svg"/></li> : ''}
				</Fragment>
	}
	return (
		wrap
	)
}

export const WrapperC = (props) => {
	let wrap = 	<li className="menu_item__show menu_item__putBorder">
					<Link to="/clients">
						{props.children}
					</Link>
				</li>
	if(props.active === "c") {
		wrap = <li className="menu-active menu_item__show menu_item__putBorder">
					<Link to="/clients">
						{props.children}
					</Link>
				</li>
	}
	if(props.firstTimer && !props.firstTimer.finished) {
		wrap = 	<Fragment>
					<li className="firstTimer menu_item__show menu_item__putBorder">
						{props.children}
					</li>
				{props.firstTimer.products && !props.firstTimer.clients ? <li className="active-guide-clients"><img src="images/guide/guide-clients.svg"/></li> : ''}
				</Fragment>
	}
	return (
		wrap
	)
}

export const WrapperT = (props) => {
	let wrap = 	<li className={`menu_item__show ${props.noBalance ? "menu_item__putBorder" : "menu_item__removeBorder"}`}>
					<Link to="/taxes">
						{props.children}
					</Link>
				</li>
	if(props.active === "t") {
		wrap = <li className={`menu-active menu_item__show ${props.noBalance ? "menu_item__putBorder" : "menu_item__removeBorder"}`}>
					<Link to="/taxes">
						{props.children}
					</Link>
				</li>
	}
	if(props.firstTimer && !props.firstTimer.finished) {
		wrap = 	<Fragment>
					<li className={`firstTimer menu_item__show ${props.noBalance ? "menu_item__putBorder" : "menu_item__removeBorder"}`}>
						{props.children}
					</li>
				{props.firstTimer.clients && !props.firstTimer.taxes ? <li className="active-guide-taxes"><img src="images/guide/guide-taxes.svg"/></li> : ''}
				</Fragment>
	}
	return (
		wrap
	)
}

export const WrapperS = (props) => {
	let wrap = 	<li className={`menu_item__show ${props.noBalance ? 'menu_item__show menu_item__putBorder' : 'menu_item__doNotShow'}`}>
					<Link to="/settings">
						{props.children}
					</Link>
				</li>
	if(props.active === "s") {
		wrap = <li className={`menu-active menu_item__show ${props.noBalance ? 'menu_item__show menu_item__putBorder' : 'menu_item__doNotShow'}`}>
					<Link to="/settings">
						{props.children}
					</Link>
				</li>
	}
	if(props.firstTimer && !props.firstTimer.finished) {
		wrap = 	<li className={`firstTimer menu_item__show ${props.noBalance ? 'menu_item__show menu_item__putBorder' : 'menu_item__doNotShow'}`}>
					{props.children}
				</li>
	}
	return (
		wrap
	)
}
