import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom"
import { I18nContextProvider } from "./languages/i18n"

ReactDOM.render(<I18nContextProvider>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</I18nContextProvider>, document.getElementsByClassName('root')[0]);
