import React from 'react'
import Skeleton from './skeletonElement'
import Shimmer from './shimmer'

const SkeletonLabel = () => {
  return (
    <div className="skeleton-wrapper">
      <Skeleton type="label"/>
      <Shimmer/>
    </div>
  )
}

export default SkeletonLabel
