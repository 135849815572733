import React, { useContext } from 'react'
import { I18nContext } from '../../languages/i18n'

const GDPR = (props) => {
	const { translate } = useContext(I18nContext)

	let style = {display: 'block'}
	if(props.showCookieMessage === false) {
		style = {display: 'none'}
	}

	return (
		<div className="gdpr" style={style}>
			<div className="gdpr-inner">
				<img className="cookie-icon" src="/images/icons/cookie.png" alt="cookie"/>
				<p className="cookie-message p18semB">
					{translate('gdpr1')} <a target="_blank" href="/privacy-policy">{translate('gdpr2')} <span>{translate('gdpr3')}</span>.</a>
				</p>
				<div className="form-btn" onClick={props.agreeWithPrivPolicy}>OK</div>
			</div>
		</div>
	)
}

export default GDPR
