import React, { useContext } from 'react'
import NotLoggedMenu from "../menus/not-logged-menu"
import { I18nContext } from "../../../languages/i18n"
import { Link } from "react-router-dom"

const LandingHeader = props => {
	const { langCode, dispatch } = useContext(I18nContext)

	const changeLanguage = (lang) => {
		dispatch({ type: "setLanguage", payload: lang });
	}

	return (
		<header-2>
			<div className="landing-header header-wrapper">
				<div className="logo">
					<Link to="/">
						<img src="/images/logo/logo-w.svg" alt=""></img>
					</Link>

				</div>
					<div className="menu-lang">
						<NotLoggedMenu/>
						<div className="languages">
							<div className="current-language">
								<img src={`/images/flags/${langCode}.svg`} alt=""/>
							</div>
							<div className="dropdown-language">
								<img src="/images/flags/en.svg" style={{display: `${langCode === 'en' ? 'none' : 'block'}`}} className="w" onClick={() => changeLanguage('en')} />
								<img src="/images/flags/lt.svg" style={{display: `${langCode === 'lt' ? 'none' : 'block'}`}} className="w" onClick={() => changeLanguage('lt')} />
							</div>
						</div>
					</div>
				</div>
			</header-2>
	)
}

export default LandingHeader
